import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_WORDPRESS_URL + 'wp-json/'
});

const PUBLIC_ROUTES = ["login", "forgot-password", "recover-password", "register", "documentation"];

const validateToken = async () => {
  try {
    await axiosInstance.post('jwt-auth/v1/token/validate');
    return true;
  } catch (error) {
    return false;
  }
};

const checkAuth = async () => {
  const TOKEN = localStorage.getItem('token');
  const currentPath = window.location.pathname;
  const isPublicPage = PUBLIC_ROUTES.some(route => currentPath.includes(route));

  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`;

  axiosInstance.interceptors.request.use(
    function (config) {
      // Código removido para não mostrar o indicador de carregamento
      // document.body.classList.add('loading-indicator');
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    function (response) {
      // Código removido para não esconder o indicador de carregamento
      // document.body.classList.remove('loading-indicator');
      return response;
    },
    function (error) {
      // Código removido para não esconder o indicador de carregamento
      // document.body.classList.remove('loading-indicator');

      // Verificar se o erro é de autenticação
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        localStorage.removeItem('token');
      }

      return Promise.reject(error);
    }
  );

  const isValid = await validateToken();

  // Caso esteja sem o token, e em rotas privadas, redirecionar para a página de login
  if (!TOKEN && !isPublicPage) {
    window.location.href = '/login';
    return false;
  }

  // Caso tenha token válido e esteja na rota de login, redirecionar para a página de perfil
  if (TOKEN && isValid && currentPath === "/login") {
    window.location.href = '/app/settings-profile';
  }
  
  // Caso esteja em rotas privadas, validar o token
  if (!isPublicPage) {
    if (!isValid) {
      // Redirecionar para a página de login se o token não for válido
      localStorage.removeItem('token');
      window.location.href = '/login';
      return false;
    }
  }

  return true;
};

// Chama checkAuth no início da aplicação para configurar os headers e interceptores
checkAuth();

export default checkAuth;
export { axiosInstance, PUBLIC_ROUTES };
